<template>
  <div class="mainContent">
    <div class="outWrap hid-scrollbar">
      <div :class="['group']">
        <OutItem
          className="n8"
          :data="item"
          :ET="true"
          v-for="(item, key) in outData.n8.slice(0, 4)"
          :key="key + 'n8'"
        />
      </div>
      <div class="margin"></div>
      <div :class="['group']">
        <OutItem
          className="n4"
          :data="item"
          :ET="true"
          v-for="(item, key) in outData.n4.slice(0, 2)"
          :key="key + 'n4'"
        />
      </div>

      <div class="margin"></div>
      <div :class="['group']">
        <OutItem
          className="n2"
          :data="item"
          :ET="true"
          v-for="(item, key) in outData.n2.slice(0, 1)"
          :key="key + 'n2'"
        />
      </div>
      <div class="margin"></div>
      <div :class="['group']">
        <OutLastItem className="n1" :ET="true" :data="outData.n1" />
      </div>
      <!-- <div class="margin"></div> -->

      <div :class="['group', 'bottom']">
        <OutItem
          className="n2"
          :data="item"
          :ET="true"
          :bottom="true"
          v-for="(item, key) in outData.n2.slice(1, 2)"
          :key="key + 'n2'"
        />
      </div>
      <!-- <div class="margin"></div> -->

      <div :class="['group', 'bottom']">
        <OutItem
          className="n4"
          :data="item"
          :ET="true"
          :bottom="true"
          v-for="(item, key) in outData.n4.slice(2, 4)"
          :key="key + 'n4'"
        />
      </div>
      <!-- <div class="margin"></div> -->

      <div :class="['group', 'bottom']">
        <OutItem
          className="n8"
          :data="item"
          :ET="true"
          :bottom="true"
          v-for="(item, key) in outData.n8.slice(4, 8)"
          :key="key + 'n8'"
        />
      </div>

      <div :class="['group', 'bottom']">
        <Out3rdPlace className="n3" :ET="true" :data="outData.n3" />
      </div>
    </div>
  </div>
</template>

<script>
import OutItem from "./OutItem.vue";
import OutLastItem from "./OutLastItem.vue";
import Out3rdPlace from "./Out3rdPlace.vue";
export default {
  name: "Out",
  props: ["outData"],
  components: {
    OutItem,
    OutLastItem,
    Out3rdPlace,
  },
};
</script>
<style lang="scss" scoped>
.mainContent {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  background: #f4f4f4;
  padding-bottom: 75px;
  height: calc(100vh - 88px);
  box-sizing: border-box;
  .outWrap {
    margin: 8px;
    background: #fff;
    padding: 15px 6px;
    border-radius: 8px;

    .group {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }

    .margin {
      margin-top: 12px;
    }
  }
}
</style>
<style lang="scss">
.dark {
  .mainContent {
    background: #000;
    color: #fff;
    .outWrap {
      background: #151618;
    }
  }
}
</style>